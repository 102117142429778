<template>
	<div class="h-100" :class="{ sticky: active }">
		<div id="js-header" class="page-header bg-white">
			<!-- <b-container> -->
				<div class="restaurant">
					<div class="restaurant-inner d-flex h-100 align-items-md-center">
						<div class="restaurant-brand">
							<b-skeleton v-if="configLoader" class="mb-2" animation="wave" width="70px" height="70px"></b-skeleton>
							<img v-else :src="logoUrl" alt=""/>
						</div>
						<div class="restaurant-head">
							<b-skeleton v-if="configLoader" class="mb-2" animation="wave" width="200px" height="30px"></b-skeleton>
							<h2 v-else class="restaurant-title h1 mb-0">
								{{ storeName }}
							</h2>
							<div class="restaurant-meta">
								<ul class="list-unstyled d-flex flex-wrap mb-0">
									
									<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-md-0 mr-2 mr-lg-4">
										<div v-if="configLoader">
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-location mr-1">
												<use xlink:href="#icon-location"></use>
											</svg>
											<span>
												{{ storeAddress }}
											</span>
											<a href="#" @click="changeLocation()">
												<svg class="icon icon-edit edit-icon">
													<use xlink:href="#icon-edit"></use>
												</svg>
											</a>
										</div>
									</li>
									<li class="restaurant-meta_item align-items-center mb-2 mb-md-0 mr-2 mr-lg-4 d-none d-md-flex">
										<div v-if="configLoader">
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-clock mr-1">
												<use xlink:href="#icon-clock"></use>
											</svg>
											<span> {{ storeWorkingHours }} </span>
										</div>
									</li>
									<li
										v-if="true"
										class="restaurant-meta_item align-items-center mb-2 mb-md-0 mr-0 mr-lg-4 d-none d-md-flex"
									>
										<div v-if="configLoader" > 
											<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
										</div>
										<div v-else>
											<svg class="icon icon-call mr-1">
												<use xlink:href="#icon-call"></use>
											</svg>
											<a :href="'tel:' + storePhone">
												{{ storePhone }}
											</a>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="restaurant-meta d-md-none">
						<b-skeleton v-if="configLoader" class="mb-2" animation="wave" width="70px" height="0px"></b-skeleton>
						<ul v-else class="list-unstyled d-flex mb-0">
							<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-lg-0 mr-2 mr-lg-4">
								<svg class="icon icon-clock mr-1">
									<use xlink:href="#icon-clock"></use>
								</svg>
								<span> {{ storeWorkingHours }} </span>
							</li>
							<li class="restaurant-meta_item d-flex align-items-center mb-2 mb-lg-0 mr-0 mr-lg-4">
								<svg class="icon icon-call mr-1">
									<use xlink:href="#icon-call"></use>
								</svg>
								<a :href="'tel:' + storePhone">
									{{ storePhone }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			<!-- </b-container> -->
		</div>

		<div class="pickup bg-white" >
			<!-- <b-container class="h-100"> -->
				<div v-if="configLoader" class="d-flex align-items-center h-100 ml-2 ml-lg-4">
					<b-skeleton animation="wave" width="200px" height="20px"></b-skeleton>
				</div>
				<b-row v-else class="pickup-container justify-content-between align-items-center h-100">
					<div class="h-100">
						<div class="d-flex align-items-center h-100">
							<div class="pickup-inner"><a href="#" @click="openSettingsModal()">{{ diningOptionName }} - {{serveAt?'at '+serveAt:'ASAP'}}</a>
							</div>
							<a href="#" @click="openSettingsModal()" class="pickup-edit">
								<svg class="icon icon-edit">
									<use xlink:href="#icon-edit"></use>
								</svg>
							</a>
						</div>
					</div>
					<b-col cols="4" class="d-flex align-items-center justify-content-end">
						<div class="d-lg-none">
							<a href="#" class="basket-trigger d-inline-flex mt-1 align-items-center" @click.prevent="toggleSidebar">
								<svg class="icon icon-basket">
									<use xlink:href="#icon-basket"></use>
								</svg>
								<span class="badge">{{ itemsCount }}</span>
							</a>
						</div>
						<!-- <b-button variant="primary" @click="openLoginModal()">Login</b-button> -->
					</b-col>
				</b-row>
			<!-- </b-container> -->
		</div>

		<main class="page-content">
			<slot/>
		</main>

		<ModalDiningOption v-if="diningOptionName"/>
		<LoginModal />
		<RestuarantClosedModal />
		<StoreSelectionModal :stores="stores" @store-selected="handleStoreSelection" ref="storeSelectionModal"/>

	</div>
</template>
<script>
import ModalDiningOption from "../components/ModalDiningOption";
import LoginModal from "../components/LoginModal.vue";
import {mapActions} from 'vuex'
import dayjs from 'dayjs';
import RestuarantClosedModal from "../components/RestaurantClosedModal.vue";
import StoreSelectionModal from "../components/StoreSelectionModal.vue";
import moment from 'moment-timezone';
import indexDbHelper from '@/helpers/indexDbHelper';


export default {
	name: "MainLayout",
	components: { ModalDiningOption, LoginModal, RestuarantClosedModal, StoreSelectionModal },
	data () {
		return {
			active: false,
			config: {
				name: ""
			},
			newDiningOption: ""
		};
	},
	computed: {
		storeName () {
			return this.$store.getters.getStoreName;
		},
		storeWorkingHours() {
			const diningOptionId = this.$store.getters.dining_option;
			const diningOption = this.$store.state.availableDiningOptions.find(option => option.id === diningOptionId);
			const timezone = this.$store.getters.getStoreTimezone;

			if (diningOption) {
				if (diningOption.timings.length > 0) {
					const today = moment().tz(timezone);
					const todayDay = today.day() || 7;

					const timingsForToday = diningOption.timings.filter(
						timing => timing.day_of_week === todayDay && !timing.is_closed
					);

					if (timingsForToday.length > 0) {
						const slots = timingsForToday.map(timing => {
							const openTime = moment.tz(timing.opening_time, "HH:mm:ss", timezone);
							const closeTime = moment.tz(timing.closing_time, "HH:mm:ss", timezone);
							return `${openTime.format('hh:mm A')} - ${closeTime.format('hh:mm A')}`;
						});

						return `Open from: ${slots.join(", ")}`;
					}

					return "Closed Today";
				} else {
					const store = this.$store.state.store;

					const openTime = moment.tz(store.start_time, "HH:mm:ss", timezone);
					const closeTime = moment.tz(store.close_time, "HH:mm:ss", timezone);

					return `Open from ${openTime.format('hh:mm A')} - ${closeTime.format('hh:mm A')}`;
				}
			}
			return "Not Available";
		},
		storeAddress () {
			return this.$store.getters.getStoreAddress;
		},
		logoUrl () {
			return this.$store.getters.getStoreLogo;
		},
		storePhone () {
			return this.$store.getters.getStorePhone;
		},
		diningOptionName () {
			return this.$store.getters.getDiningOptionName;
		},
		itemsCount: function () {
			return this.$store.getters.getItemsCount;
		},
		serveAt: function () {
			const serveAt = this.$store.getters.getServeAt;
			return serveAt? (dayjs(this.$store.getters.getServeAt).format('DD/MM/YYYY HH:mm')): '';
		},
		configLoader(){
			return this.$store.getters.configLoader;
		},
		stores(){
			return this.$store.getters.getStores;
		}
	},
	created () {
		document.title = this.$store.getters.getStoreName.toString().trim()
			? `${this.$store.getters.getStoreName} - Online Ordering`
			: "Online Ordering";
	},
	methods: {
		...mapActions({
			toggleLoginModal:'auth/toggleLoginModal'
		}),
		toggleSidebar () {
			this.$store.commit("toggleSidebar");
		},
		openSettingsModal: function () {
			this.chosenDiningOption = this.dining_option;
			return this.$bvModal.show("modal-order-settings");
		},
		openLoginModal(){
			localStorage.setItem('loginClosed',false)
			this.$store.commit('auth/SET_OPEN_LOGIN_MODAL',true)
			// localStorage.setItem('loginClosed',false)
		},
		changeLocation(){
			this.$refs.storeSelectionModal.showModal();
		},
		async handleStoreSelection(store) {
			if (this.$parent && this.$parent.prefetchData) {
				await this.$store.dispatch('toggleConfigLoader')
				await this.$parent.prefetchData(store.id);
				this.$store.commit('SET_ITEMS', []);
				await indexDbHelper.clearAllData('orderItems');
				await this.$store.dispatch('toggleConfigLoader')
			}
    	}
	},
	mounted () {
		// const forcedLoginModalClosed =  localStorage.getItem('loginClosed')
		// if(forcedLoginModalClosed &&  forcedLoginModalClosed === 'false')
		// 	this.toggleLoginModal(true)
		// else if(!forcedLoginModalClosed)
		// 	this.toggleLoginModal(true)

		window.document.onscroll = () => {
			const navBar = document.getElementById("js-header");
			this.active = window.scrollY > navBar.offsetTop;
		};
	}
};
</script>
<style scoped>
.pickup-container {
	@media screen and (max-width: 2200px) {
		margin-left: 2%;
		margin-right: 2%
	}
	@media screen and (max-width: 990px) {
		margin-left: 2% !important;
		margin-right: 2% !important;
	}}

.edit-icon {
	margin-left: 0.5rem;
	width: 1rem;
	height: 1rem;
}
</style>