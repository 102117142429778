<template>
	<b-modal id="modal-order-settings" @ok="saveDinnerOption" @show="onShow">
		<template #modal-header="{ close }">
			<b-button @click="close()" class="close">
				<svg class="icon icon-close">
					<use xlink:href="#icon-close"></use>
				</svg>
			</b-button>

			<div class="modal-top p-3">
				<h4 class="modal-title mb-2">
					Your order settings
				</h4>
			</div>
		</template>

		<template #default>
			<div class="settings px-3 pb-4">
				<ul class="nav nav-pills">
					<li class="nav-item" v-for="availableDiningOption in availableDiningOptions" :key="availableDiningOption.id">
						<a class="nav-link" @click="setNewDiningOption(availableDiningOption.id)"
							 :class="{'active': chosenDiningOption===availableDiningOption.id}">{{ availableDiningOption.name }}</a>
					</li>
				</ul>
				<div class="settings-meta">
					<p v-html="chosenDiningOptionObject.description" v-if="chosenDiningOptionObject.description"></p>
				</div>

				<b-form-group
					v-if="chosenDiningOptionObject.is_deliverable"
					id="input-group-2"
					label-for="input-2"
				>
				<template #label>
					<div class="d-flex align-items-center">
						<span>Delivery Address *</span>
						<span v-if="addresses.length > 0 && userId" class="ml-auto">
							<b-button variant="primary" @click="toggleAddressSelection()">{{enableAddressTextFieldLabel}}</b-button>
						</span>
					
					</div>
				</template>
				<div v-if="addresses.length > 0 && userId && !enableAddressTextField" class="address-container">
					<b-form-select v-model="delivery_address" :options="addressOptions"></b-form-select>
				</div>

				<div v-else class="address-container">
					<!-- <div>
						<b-form-select id="form-state" class="mt-2" v-if="delivery_address.country === 'US'" v-model="delivery_address.state" :options="statesOptions">
							<template #first>
								<b-form-select-option
								  :value="null"
								  disabled
								>
								  -- Please select a state --
								</b-form-select-option>
							  </template>
						</b-form-select>
						<b-form-input id="form-state" class="mt-2" v-else v-model="delivery_address.state" placeholder="Enter State"></b-form-input>
					</div> -->
					<b-form-input id="form-address" class="mt-2" v-model="delivery_address.address_1" :placeholder="addressFields.address_1"></b-form-input>
					<span v-if="isAddressMissing"><small class="text-danger error-label">Address required *</small></span>
					<b-form-input id="form-appartment" class="mt-2" v-model="delivery_address.address_2" :placeholder="addressFields.address_2"></b-form-input>
					<b-form-input id="form-city" class="mt-2" v-model="delivery_address.city" :placeholder="addressFields.city"></b-form-input>
					<b-form-input id="form-state" class="mt-2" :disabled="addressFields.state === null" :hidden="addressFields.state === null" v-model="delivery_address.state" :placeholder="addressFields.state"></b-form-input>
					<b-form-input id="form-postalcode" class="mt-2" v-model="delivery_address.postal_code" :placeholder="addressFields.postal_code"></b-form-input>
					<!-- <b-form-input
						id="input-2"
						v-model="delivery_address"
						v-on:input="$v.delivery_address.$touch"
						v-bind:class="getClass($v.delivery_address)"
						required
						placeholder="Enter address"
						@input="search"
					></b-form-input>
					<b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.delivery_address.required">This is a required
						field
					</b-form-invalid-feedback>
					<div class="address-container__dropdown">
						<ul>
							<li v-for="place in searchedPlaces" :key="place.place_id">
							 <span @click="selectPlace(place.display_name)">{{ place.display_name }}</span>
							</li>
						</ul>
					</div> -->
				</div>

				</b-form-group>
				<b-form-group>
					<b-form-radio-group
						id="radio-group-2"
						v-model="pickupSettings"
						name="pickup_when"
						class="d-block"
						value="now"
					>
						<b-form-radio value="now" class="settings-item"><strong>ASAP</strong></b-form-radio>
						<b-form-radio v-if="isScheduleEnabled()" value="later" class="settings-item"><strong>Schedule</strong> for
							later
						</b-form-radio>
					</b-form-radio-group>
				</b-form-group>

				<div class="mt-4">
					<b-form-group
						id="select-group-1"
						v-if="pickupSettings === 'later' && isScheduleEnabled()"
						label="Select Date"
						label-for="select-1"
					>
						<b-form-datepicker v-model="delivery_date" :min="delivery_date_min" :max="delivery_date_max"
															 locale="en"></b-form-datepicker>
					</b-form-group>
					<b-form-group id="select-group-2" v-if="pickupSettings==='later' && isScheduleEnabled()" label="Select Time"
												label-for="select-2">
						<b-form-timepicker
							v-model="delivery_time"
							v-if="pickupSettings === 'later'"
							locale="en"
							label-no-time-selected
							:state="!deliveryTimeLimitExceed"></b-form-timepicker>
						<b-form-invalid-feedback id="input-2-live-feedback" v-if="deliveryTimeLimitExceed">Please select an appropiate delivery time. The delivery time should not be before {{ timeLimit}}
						</b-form-invalid-feedback>
					</b-form-group>
				</div>
			</div>
		</template>

		<template #modal-footer="{ ok }">
			<b-button variant="primary" class="w-100" squared size="lg" @click="ok()" :disabled="$v.$invalid || deliveryTimeLimitExceed">
				<span>Update</span>
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import { mapActions, mapGetters } from "vuex"
import deliveryOptionMixin from "@/mixins/deliveryOption.mixin";

export default {
	data () {
		return {
			// chosenDiningOption: null,
			// chosenServeNow: true,
			// chosenDiningOptionObject: {
			// 	name: "",
			// 	description: ""
			// },
			// pickupSettings: "now",
			// delivery_address:{
			// 	country:null,
			// 	state:null,
			// 	first_name:'',
			// 	last_name:'',
			// 	city:'',
			// 	postal_code:'',
			// 	phone:'',
			// 	address_1:'',
			// 	address_2:'',
			// },
			// initial: null,
			// delivery_date_min: null,
			// delivery_date_max: null,
			// delivery_date: null,
			// delivery_time: null,
			// deliveryTimeLimitExceed: false,
			// timeLimit:null,
			// enableAddressTextField:false,
			// enableAddressTextFieldLabel:'Add new address',
			// isAddressMissing:false,
			// addressSpaceRegex:/^ +$/,
			// addressEmptyRegex:/^$/
		};
	},
	mixins:[deliveryOptionMixin],
	validations () {
		if (this.chosenDiningOptionObject && this.chosenDiningOptionObject.is_deliverable) {
			return {
				delivery_address: {
					required
				}
			};
		} else {
			return {};
		}
	},
	// async created() {
	// 	await this.fetchCountriesAndStates()
	// 	const initialDate = new Date();
	// 	const minDatetime = new Date(initialDate);
	// 	const maxDatetime = new Date(initialDate);
	// 	this.delivery_date_min = new Date(minDatetime.setMinutes(minDatetime.getMinutes() + 30));
	// 	this.delivery_date_max = new Date(maxDatetime.setDate(maxDatetime.getDate() + 7));
	// 	this.delivery_date = initialDate.toISOString().split("T")[0];
	// 	const delivery_time = new Date(initialDate);
	// 	delivery_time.setMinutes(delivery_time.getMinutes() + 30);
	// 	this.delivery_time = `${("0" + delivery_time.getHours()).substr(-2)}:${("0" + delivery_time.getMinutes()).substr(-2)}:${("0" + delivery_time.getSeconds()).substr(-2)}`;
	// 	this.chosenDiningOption = this.$store.getters.dining_option;
	// 	setInterval(() => {
	// 		// if the order to be placed is for delivery and is to be scheduled later
	// 		if (this.chosenDiningOptionObject.is_deliverable && this.pickupSettings === "later") {
	// 		// get current time + 30 minutes
	// 			const timeLimit = moment().add({
	// 				'minutes': 30
	// 			}).format("HH:mm")
	// 			// convert 24hrs to 12 hrs format to show on screen
	// 			this.timeLimit = moment(timeLimit, 'HH:mm:ss').format("h:mm A")
	// 			// convert removing seconds from the delivery_time
	// 			const deliveryTime = moment(this.delivery_time, "HH:mm:ss").format("HH:mm")

	// 			if (deliveryTime < timeLimit) {
	// 				this.deliveryTimeLimitExceed = true
	// 			}
	// 			else {
	// 				this.deliveryTimeLimitExceed = false
	// 			}
	// 		}
	// 		else { 
	// 			// update the delivery time timepicker after every half second
	// 			this.delivery_time = moment().add({
	// 				'minutes': 30
	// 			}).format("HH:mm")
	// 		}
	// 	}, 500)
	// },
	methods: {
		...mapActions(
			{
				searchPlace:'order/searchPlace'
				// fetchCustomerAddresses:'address/fetchCustomerAddresses',
				// fetchCountriesAndStates:'address/fetchCountriesAndStates'
			}
		),
		onShow () {
			this.chosenDiningOption = this.$store.getters.dining_option;
			this.chosenDiningOptionObject = this.availableDiningOptions.find(d => d.id === this.chosenDiningOption);
			this.pickupSettings = !!this.$store.getters.getServeAt;
			this.pickupSettings = this.serveAt ? "later" : "now";
		},
		setNewDiningOption (newDiningOptionId) {
			this.chosenDiningOption = newDiningOptionId;
			this.chosenDiningOptionObject = this.availableDiningOptions.find(d => d.id === newDiningOptionId);
			if (!this.chosenDiningOptionObject.is_scheduling_enabled) {
				this.pickupSettings = "now";
			}
		},
		isScheduleEnabled () {
			const scheduleObj = this.availableDiningOptions.find(d => d.id === this.chosenDiningOption);
			return scheduleObj && scheduleObj.is_scheduling_enabled;
		},
		getClass ($v) {
			return {
				"is-invalid": $v.$error,
				valid: $v.$dirty && !$v.$invalid
			};
		},
		search() { 
			if(this.delivery_address!=='')
				this.debounce(() => { this.searchPlace(this.delivery_address) }, 3000)
			else
				this.searchPlace('')
		},
		selectPlace(place) { 
			this.delivery_address = place
			this.searchPlace('')
		},
		debounce(method, timer) {
			if (this.$_debounceTimer !== null) {
				clearTimeout(this.$_debounceTimer);
			}
			this.$_debounceTimer = setTimeout(() => {
				method();
			}, timer);
		},
		toggleAddressSelection(){
			this.enableAddressTextField = !this.enableAddressTextField
			if(this.enableAddressTextField){
				this.enableAddressTextFieldLabel = 'Choose from exsisting address'
				this.delivery_address = ''
			}
			else{
				this.enableAddressTextFieldLabel = 'Add new address'
				this.delivery_address = ''
			}

		}
	},
	computed: {
		...mapGetters({ 
			searchedPlaces:'order/searchedPlaces',
			userId : 'auth/activeUserId',
			addresses : 'address/addresses',
			addressOptions: 'address/addressOptions',
			countryOptions:'address/countries',
			statesOptions:'address/states'
	
	}),
		diningOptionName () {
			return this.$store.getters.getDiningOptionName;
		},
		availableDiningOptions () {
			return this.$store.getters.availableDiningOptions;
		},
		serveAtNew () {
			return new Date(`${this.delivery_date}T${this.delivery_time}`).toISOString();
		},
		serveAt () {
			return this.$store.getters.getServeAt;
		},
		addressFields() {
			return this.$store.getters.getStoreAddressFields;
		}
	},
	watch:{
		userId:{
			async handler(val){
				if(val){
					await this.fetchCustomerAddresses(val)
				}
			}
		}
	}
};
</script>


<style lang="scss">
.address-container {
	position: relative;
	&__dropdown {
		position: absolute;
		top: 50px;
		background: #fff;
		z-index: 2;
		padding: 0; 
		max-height: 117px; 
		overflow-y: auto; 
		border: 1px solid gray; 
		width: 100%;
		scrollbar-width: thin;
		ul {
			padding: 0;
			margin: 0;
			li {
				list-style: none; 
				border-bottom: 1px solid rgb(216, 216, 216); 
				padding: 10px 15px; 
				line-height: normal; 
				overflow: hidden;
				cursor: pointer;
				span {
					display: -webkit-box; 
					-webkit-line-clamp: 1; 
					line-clamp: 1; 
					-webkit-box-orient: vertical; 
					height: 18px; 
					overflow: hidden;
				}
				&:last-child {
					border-bottom: 0;
				}
				&:hover {
					background-color: rgba(218, 218, 218, 0.233);
				}
			}
		}
	}
}
</style>