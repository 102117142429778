<template>
  <b-modal
    id="store-selection-modal"
  >
  <template #modal-header="{ close }">
			<b-button @click="close()" class="close">
				<svg class="icon icon-close">
					<use xlink:href="#icon-close"></use>
				</svg>
			</b-button>

			<div class="modal-top p-3">
				<h4 class="modal-title mb-2">
					Select Store
				</h4>
			</div>
		</template>
    <div class="store-list">
      <div
        v-for="store in stores"
        :key="store.id"
        class="store-item p-3 mb-3"
        :class="{ 'selected': selectedStore && selectedStore.id === store.id }"
        @click="selectStore(store)"
      >
        <h5 class="mb-2">{{ store.name }}</h5>
        <p class="mb-1">
          <svg class="icon icon-location mr-1 icon-loc">
						<use xlink:href="#icon-location"></use>
					</svg>
          {{ store.address }}, {{ store.city }}</p>
      </div>
    </div>
    <template #modal-footer>
			<b-button 
        variant="primary"
        class="w-100"
        squared
        size="lg"
        :disabled="!selectedStore || (selectedStore.id === getStore.id)"
        @click="confirmSelection">
				<span>Update</span>
			</b-button>
		</template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StoreSelectionModal',
  props: {
    stores: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedStore: null
    }
  },
  computed: {
    ...mapGetters(['getStore'])
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'store-selection-modal') {
        this.selectedStore = this.getStore
      }
    })
  },
  methods: {
    selectStore(store) {
      this.selectedStore = store
    },
    confirmSelection() {
      this.$emit('store-selected', this.selectedStore)
      this.$bvModal.hide('store-selection-modal')
    },
    showModal() {
      this.selectedStore = this.getStore
      this.$bvModal.show('store-selection-modal')
    }
  },
  mounted() {
    console.log(this.stores)
  }
}
</script>

<style scoped>
.store-item {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.store-item:hover {
  border-color: #2196f3;
  background-color: #f8f9fa;
}

.store-item.selected {
  border-color: #2196f3;
  background-color: #f8f9fa;
}

.store-item i {
  color: #6c757d;
}

.store-list {
  padding: 0 1rem;
	max-height: 300px;
	overflow-y: auto;
}

.icon-loc {
	color: #2196f3;
  margin-bottom: 4px;
}
</style>
