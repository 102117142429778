<template>
	<div class="page-content bg-light py-5">
		<b-container>
			<b-row class="justify-content-center">
				<b-col cols="12" md="8" lg="6">
					<b-card class="text-center shadow-sm border-0 p-3">
						<div v-if="loading" class="text-center">
							<b-spinner variant="primary" label="Loading..."></b-spinner>
							<p class="mt-2">Processing your order...</p>
						</div>

						<div v-else-if="error" class="text-center">
							<h5 class="mb-3">{{ storeAlias }} - Order #{{ orderId }}</h5>
							<h2 class="mb-4 text-primary">
								You're Here—We're On Our Way!
							</h2>
							<p class="mb-4 text-danger">
								{{ error }}
							</p>
						</div>

						<div v-else-if="submitted" class="text-center">
							<h5 class="mb-3">{{ storeAlias }} - Order #{{ orderId }}</h5>
							<h2 class="mb-4 text-primary">
								You're Here—We're On Our Way!
							</h2>
							<p class="mb-4 text-default">
								Thank you for letting us know you've arrived! Our team has been notified and will bring your order out shortly.
							</p>
						</div>

						<div v-else>
							<h5 class="mb-3">{{ storeAlias }} - Order #{{ orderId }}</h5>
							<h2 class="mb-4 text-primary">
								Let us know you're here!
							</h2>
							<b-form @submit.prevent="submitPickup">
								<b-form-group
									label="Car Model & Color"
									label-for="car-model"
									class="text-left"
								>
									<b-form-input
										id="car-model"
										v-model="carModel"
										placeholder="Enter your car model and color"
										required
									></b-form-input>
								</b-form-group>

								<b-form-group
									label="Parking Number/Spot"
									label-for="parking-number"
									class="text-left"
								>
									<b-form-input
										id="parking-number"
										v-model="parkingNumber"
										placeholder="Enter your parking spot number"
										required
									></b-form-input>
								</b-form-group>

								<b-button
									type="submit"
									variant="primary"
									class="w-100 mt-3"
									:disabled="submitting"
								>
									<b-spinner small v-if="submitting" class="mr-2"></b-spinner>
									{{ submitting ? 'Submitting...' : 'I\'m Here - Notify Staff' }}
								</b-button>
							</b-form>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { orderPickup } from '@/helpers/apiHelper';
import getStoreInfo from '@/helpers/apiHelper';

export default {
	name: 'OrderPickup',
	components: {},
	data() {
		return {
			loading: true,
			error: null,
			storeAlias: null,
			orderId: null,
			carModel: '',
			parkingNumber: '',
			submitting: false,
			submitted: false,
			token: null
		};
	},
	async mounted() {
		try {
			this.token = this.$route.query.token || null;
			this.storeAlias = this.$route.params.storeAlias;
			await this.$store.dispatch("setStoreAlias", this.storeAlias);
			const storeInfoResponse = await getStoreInfo(this.storeAlias);
			if (storeInfoResponse.status === 404) {
					await this.$router.push({ name: "NotFound" });
			} else {
				const response = await storeInfoResponse.json();
				await this.$store.dispatch("setBaseUrl",response.dashboard_link)
			}
			this.orderId = this.$route.params.id;
			this.loading = false;
		} catch (err) {
			this.error = err?.data?.message || 'An error occurred';
			this.loading = false;
		}
	},
	methods: {
		async submitPickup() {
			try {
				this.submitting = true;
				await orderPickup(this.orderId, this.carModel, this.parkingNumber, this.token);
				this.submitted = true;
				this.submitting = false;
			} catch (err) {
				this.error = err?.data?.message || 'An error occurred while notifying staff';
				this.submitting = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.page-content {
	min-height: 100vh;
}

.card {
	background: white;
	border-radius: 3px;

	.card-body {
		padding: 2rem;
	}
}
</style>