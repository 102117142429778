import Vue from "vue";
import Vuex from "vuex";
import sumBy from "lodash/sumBy";
import { dinnerOptions } from "../helpers/constants";
import { order } from "./modules/order"
import { auth } from "./modules/auth";
import {address} from "./modules/address"
import indexDbHelper from "@/helpers/indexDbHelper";
import { setTaxRound } from "../helpers/utils";

Vue.use(Vuex);

export const store = new Vuex.Store({
         modules: {
           order,
           auth,
           address
         },
         state: {
           isLoading: false,
           config: {
             dinner_option: dinnerOptions.pickup
           },
           orderSettings: {},
           store: {},
           stores: [],
           storeName: "",
           storeAlias: "",
           storePhone: "",
           storeAddress: "",
           storeLogo: "",
           categories: [],
           subtotal: 0,
           tipAmount: 0,
           showSidebar: false,
           totalTax: 0,
           dining_option: null,
           diningOptionName: "",
           availableDiningOptions: [],
           availablePaymentMethods: [],
           items: [],
           serve_at: "",
           delivery_address: {
            country:'',
            state:'',
            first_name:'',
            last_name:'',
            city:'',
            postal_code:'',
            phone:'',
            address_1:'',
            address_2:''
           },
           isStoreTaxInclusive: false,
           baseUrl:null,
           configLoader:false,
           tax_round: '',
           isStoreClosed: false,
           timezone: ''
         },
         getters: {
           getIsLoading: (state) => state.isLoading,
           getConfig: (state) => state.config,
           getStore: (state) => state.store,
           getStores: (state) => state.stores,
           getCurrencySymbol: (state) => state.store?.currency?.symbol || "$",
           getOrderSettings: (state) => state.orderSettings,
           getStoreName: (state) => state.storeName,
           getStoreAddress: (state) => state.storeAddress,
           getStoreAlias: (state) => state.storeAlias,
           getStorePhone: (state) => state.storePhone,
           getStoreAddressFields: (state) => state.store?.address_fields,
           getItems: (state) => state.items,
           getItemsCount: (state) => state.items.length,
           getSubtotal: (state) => state.subtotal,
           getTipAmount: (state) => state.tipAmount,
           getTotalTax: (state) => state.totalTax,
           getStoreLogo: (state) => state.storeLogo,
           showSidebar: (state) => state.showSidebar,
           dining_option: (state) => state.dining_option,
           getServeAt: (state) => state.serve_at,
           getDeliveryAddress: (state) => state.delivery_address,
           getDiningOptionName: (state) => state.diningOptionName,
           getDiningOptionObject: (state) => state.diningOptionObject,
           availableDiningOptions: (state) => state.availableDiningOptions,
           availablePaymentMethods: (state) => state.availablePaymentMethods,
           isStoreTaxInclusive: (state) => state.isStoreTaxInclusive,
           baseUrl : (state)=>state.baseUrl,
           configLoader: (state) => state.configLoader,
           storeCountry: (state) => state.store?.country,
           getTaxRound: (state) => state.tax_round,
           getIsStoreClosed: (state) => state.isStoreClosed,
           getStoreTimezone: (state) => state.timezone || 'UTC'
         },
         mutations: {
           SET_CONFIG(state, newConfig) {
             state.config = newConfig;
           },
           SET_STORE(state, newStore) {
             state.store = newStore;
           },
           SET_STORES(state, stores) {
            state.stores = stores;
          },
           SET_ORDER_SETTINGS(state, newSettings) {
             state.orderSettings = newSettings;
           },
           SET_STORE_NAME(state, payload) {
             state.storeName = payload;
           },
           SET_STORE_ADDRESS(state, payload) {
             state.storeAddress = payload;
           },
           SET_STORE_ALIAS(state, payload) {
             state.storeAlias = payload;
           },
           SET_STORE_PHONE(state, payload) {
             state.storePhone = payload;
           },
           SET_ITEMS(state, payload) {
             state.items = payload;
           },
           ADD_ITEM(state, payload) {
             state.items.push(payload);
             indexDbHelper.saveToDb('orderItems', state.items)     
           },
           UPDATE_ITEM(state, payload) {
             Vue.set(state.items, payload.index, payload.item);
             indexDbHelper.saveToDb('orderItems', state.items)
           },
           REMOVE_ITEM(state, payload) {
             indexDbHelper.deleteSingleInstance('orderItems', state.items[payload].local_id)
             state.items.splice(payload, 1);
           },
           SET_STORE_LOGO_URL(state, payload) {
             state.storeLogo = payload;
           },
           RECALCULATE(state) {
             state.subtotal =
               sumBy(state.items, (item) => {
                 const modTotal = sumBy(item.modifiers, (mod) => mod.price);
                 return (item.price + modTotal) * item.quantity;
               }) || 0;
             // tax

             // calculating excluisve total tax along with modifiers tax
             if (!state.isStoreTaxInclusive) {
               state.items.forEach(function (tax_item) {
                 tax_item.tax = setTaxRound(state.tax_round, (tax_item.price * (tax_item.tax_rate_value / 100)))
                 // calculating exclusive tax for modifiers
                 for (const mod of tax_item["modifiers"]) {
                  tax_item.tax += setTaxRound(state.tax_round, (mod.price * (tax_item.tax_rate_value / 100)))
                 }
               });
             }
             else { 
              state.items.forEach(function(tax_item) {
                tax_item.tax = setTaxRound(state.tax_round, (tax_item.price - (tax_item.price / (1 + tax_item.tax_rate_value)) * tax_item.tax_rate_value))
                // calculating inclusive tax for modifiers
                for (const mod of tax_item["modifiers"]) {
                  tax_item.tax += setTaxRound(state.tax_round ,(mod.price - (mod.price / (1 + tax_item.tax_rate_value)) * tax_item.tax_rate_value))
                }
              });
             }

             state.totalTax = state.items.reduce(
               (acc, current) =>
                 acc + (current.tax > 0 ? current.quantity * current.tax : 0),
               0
             );
             state.totalTax = setTaxRound(state.tax_round, state.totalTax)
           },
           toggleSidebar(state) {
             state.showSidebar = !state.showSidebar;
           },
           setAvailableDiningOptions(state, payload) {
             state.availableDiningOptions = payload;
           },
           SET_TAX_ROUND(state, payload) {
            state.tax_round = payload;
           },
           setTipAmount(state, payload) {
            state.tipAmount = payload
           },
           setAvailablePaymentMethods(state, payload) {
             state.availablePaymentMethods = payload;
           },
           setDiningOption(state, payload) {
             state.dining_option = payload;
             const diningOptionObject = state.availableDiningOptions.find(
               (d) => d.id === payload
             );
             state.diningOptionObject = diningOptionObject;
             state.diningOptionName = diningOptionObject
               ? diningOptionObject.name
               : "";
           },
           setIsLoading(state, payload) {
             state.isLoading = payload;
           },
           setServeAt(state, payload) {
             state.serve_at = payload;
           },
           setDeliveryAddress(state, payload) {
             state.delivery_address = payload;
           },
           SET_STORE_TAX_INCLUSIVE(state, isTaxInclusive) {
             if(isTaxInclusive === 2)
               state.isStoreTaxInclusive = true
             else 
                state.isStoreTaxInclusive = false
           },
           SET_BASEURL: (state,baseUrl) => state.baseUrl = baseUrl,
           TOGGLE_CONFIG_LOADER : (state, toggle) => { state.configLoader = toggle },
           setIsStoreClosed(state, payload) {
            state.isStoreClosed = payload;
           },
           SET_STORE_TIMEZONE(state, payload) {
            state.timezone = payload;
           }
         },
         actions: {
           setConfig: ({ commit }, payload) => commit("SET_CONFIG", payload),
           setStore: ({ commit }, payload) => commit("SET_STORE", payload),
           setStores: ({ commit }, payload) => commit("SET_STORES", payload),
           setOrderSettings: ({ commit }, payload) =>
             commit("SET_ORDER_SETTINGS", payload),
           setStoreName: ({ commit }, payload) =>
             commit("SET_STORE_NAME", payload),
           setStoreAlias: ({ commit }, payload) =>
             commit("SET_STORE_ALIAS", payload),
           setStoreAddress: ({ commit }, payload) =>
             commit("SET_STORE_ADDRESS", payload),
           setStorePhone: ({ commit }, payload) =>
             commit("SET_STORE_PHONE", payload),
           setItems: ({ commit }, payload) => {
             commit("SET_ITEMS", payload);
             commit("RECALCULATE");
           },
           addItem: ({ commit }, payload) => {
             commit("ADD_ITEM", payload);
             commit("RECALCULATE");
           },
           updateItem: ({ commit }, payload) => {
             commit("UPDATE_ITEM", payload);
             commit("RECALCULATE");
           },
           removeItem: ({ commit }, payload) => {
             commit("REMOVE_ITEM", payload);
             commit("RECALCULATE");
           },
           setTaxRound: ({ commit }, payload) => {
            commit("SET_TAX_ROUND", payload)
           },
           setStoreLogoUrl: ({ commit }, payload) =>
             commit("SET_STORE_LOGO_URL", payload),
           isStoreTaxInclusive: ({ commit }, payload) =>
             commit("SET_STORE_TAX_INCLUSIVE", payload),
           setBaseUrl:({commit},baseUrl)=> commit('SET_BASEURL',baseUrl),
           toggleConfigLoader: ({commit, state}) => {commit('TOGGLE_CONFIG_LOADER', !state.configLoader)},
           setStoreTimezone: ({ commit }, payload) => commit("SET_STORE_TIMEZONE", payload)
         }
       });
