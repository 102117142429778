<template>
	<div class="landing-container">
	  <div class="landing-content">
		<img src="@/assets/images/biyo_logo.png" alt="Biyo Logo" class="logo" />
		<h1>Welcome to Biyo Online Ordering</h1>
		<p>Please access the store using the specific store URL.</p>
		<p>Example: https://order.biyo.co/your-store-name</p>
	  </div>
	</div>
  </template>
  
  <script>
  export default {
	name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .landing-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
  }
  .landing-content {
	max-width: 600px;
	padding: 2rem;
  }
  .logo {
	max-width: 200px;
	margin-bottom: 2rem;
  }
  </style>